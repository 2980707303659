<template>
    <div>
        <Button type="primary" size="small" @click="onAddSpecification">添加规格</Button>

        <Modal class="modal_specification" :closable="false" v-model="showAddSpecification" title="添加规格" width="500" :loading="ajaxLoading">
            <div class="form_item">
                <div class="form_label must_input">名称</div>
                <Input class="width_300" v-model.trim="name" placeholder="请输入规格名称" />
            </div>
            <div class="form_item">
                <div class="form_label must_input align_self_start">值</div>
                <div>
                    <div class="flex align_center spec_value_item" v-for="(info, idx) in specValue" :key="'spec_item_' + idx + info.id">
                        <Input class="width_200" v-model.trim="info.value" placeholder="请输入规则值" />
                        <Button v-if="specValue.length > 1" class="ml_20" type="error" size="small" @click="onDeleteSpecValue(idx)">删除</Button>
                    </div>

                    <Button size="small" type="success" ghost shape="circle" icon="md-add-circle" @click="onAddSpecValue">添加规格值</Button>
                </div>
            </div>
            <div slot="footer" class="relative width_100_percent flex align_center justify_center">
                <Button type="primary" ghost @click="onCancelAdd">取消</Button>
                <Button class="ml_100" type="primary" :loading="ajaxLoading" @click="onConfirmAdd">确定</Button>
            </div>
        </Modal>
    </div>
</template>
<script>
import { reqSaveSpec } from '@/lib/request/auth2';

export default {
    name: 'compAddSpec',
    data() {
        return {
            showAddSpecification: false,
            name: null,
            specValue: [],
        };
    },
    methods: {
        //添加规格
        onAddSpecification() {
            this.name = null;
            this.showAddSpecification = true;
        },
        //添加规格-取消
        onCancelAdd() {
            this.showAddSpecification = false;
            window.setTimeout(() => {
                this.hideAjaxLoading();
            }, 500);
        },
        //添加规格-确认
        onConfirmAdd() {
            let name = this.name;
            if (!!!name) {
                this.fadeWarning('请输入规格名称');
                return;
            }
            if (this.specValue.length <= 0) {
                this.fadeWarning('至少添加一项规格值');
                return;
            }
            let specValueIdx = -1;
            for (let i = 0; i < this.specValue.length; i++) {
                if (!hm.isNotEmptyString(this.specValue[i].value)) {
                    specValueIdx = i;
                    break;
                }
            }
            if (specValueIdx > -1) {
                this.fadeWarning(`第${specValueIdx + 1}项规格值不能为空`);
                return;
            }
            let data = this.specValue.map(info => info.value).join(',');

            this.showAjaxLoading();
            reqSaveSpec({
                name,
                data,
            })
                .then(res => {
                    this.$emit('success');
                    this.fadeAlert(res.errmsg);
                    this.onCancelAdd();
                })
                .catch(res => {
                    this.fadeWarning(res.errmsg);
                });
        },
        //添加规格值
        onAddSpecValue() {
            this.specValue.push({
                id: hm.createId(),
                value: null,
            });
        },
        //删除规格值
        onDeleteSpecValue(idx) {
            this.specValue.splice(idx, 1);
        },
    },
};
</script>
<style lang="less" scoped>
.modal_specification {
    .form_label {
        width: 120px;
    }
    .spec_value_item {
        margin-bottom: 20px;
    }
}
</style>
