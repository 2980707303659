<template>
    <Card dis-hover>
        <div slot="title">{{ title }}</div>
        <Card title="基础信息" dis-hover>
            <div class="form_item">
                <div class="form_label must_input align_self_start">商品图片</div>
                <div>
                    <uploadImageMultiple :width="100" :height="100" v-model="formData.pic" :len="3"></uploadImageMultiple>
                    <div class="tip_txt">图片尺寸：750x700</div>
                </div>
            </div>
            <div class="form_item">
                <div class="form_label must_input align_self_start">商品缩略图</div>
                <div>
                    <UploadImage :width="100" :height="100" v-model="formData.thumbnail"></UploadImage>
                    <div class="tip_txt">图片尺寸：332x262</div>
                </div>
            </div>
            <div class="form_item">
                <div class="form_label must_input">商品名称</div>
                <Input class="width_300" type="text" v-model.trim="formData.title" :maxlength="50" show-word-limit></Input>
            </div>
            <div class="form_item">
                <div class="form_label must_input">商品分类</div>
                <Cascader :transfer="true" :data="classTypeList" trigger="hover" v-model="formData.goodClass" @on-change="evt => onChangeClassType(evt, 1)"></Cascader>
            </div>
            <div class="form_item">
                <div class="form_label">商品型号</div>
                <Input class="width_300" type="text" v-model.trim="formData.marque" :maxlength="20" show-word-limit></Input>
            </div>
        </Card>

        <Card class="mt_20" title="规格设置" dis-hover>
            <div class="form_item">
                <div class="form_label">选择规格</div>
                <Select :disabled="isEdit" multiple class="width_300" @on-change="onChangeSpecification" :value="specValue" placeholder="请选择规格">
                    <Option v-for="info in specificationList" :disabled="info.disabled" :key="info.id" :value="info.id">{{ info.name }}</Option>
                </Select>
                <compAddSpec class="ml_20" @success="getSpecList"></compAddSpec>
                <div class="tip_txt">（最多可选{{ maxSpecLength }}种规格）</div>
            </div>
            <div class="form_item">
                <div class="form_label align_self_start">规格信息</div>
                <div class="relative width_100_percent">
                    <div v-for="(info, idx) in formData.spec" :key="info.id" class="flex flex_wrap align_center msg" :class="{ mt_20: idx > 0 }">
                        <div class="form_label">{{ info.name }}</div>
                        <Select :disabled="isEdit" multiple class="width_300" v-model="info.value" @on-change="onChangeSpecValue">
                            <Option v-for="(opt, optIdx) in info.data.split(',') || []" :key="'opt_' + info.id + optIdx" :value="opt">{{ opt }}</Option>
                        </Select>
                    </div>
                </div>
            </div>
            <div>
                <Table size="small" stripe class="mt_30 width_100_percent" :columns="spec_column" :data="spec_group">
                    <template slot-scope="{ index }" slot="spec_no">
                        <Input v-model.trim="spec_group[index].spec_no" placeholder="请输入" />
                    </template>
                    <template slot-scope="{ index }" slot="spec_model">
                        <Input v-model.trim="spec_group[index].spec_model" placeholder="请输入" />
                    </template>
                    <template slot-scope="{ index }" slot="price">
                        <Input v-model.trim="spec_group[index].price" placeholder="请输入" />
                    </template>
                    <template slot-scope="{ index }" slot="stock">
                        <Input v-model.trim="spec_group[index].stock" placeholder="请输入" />
                    </template>
                    <template slot-scope="{ index }" slot="spec_id">
                        <Input v-model.trim="spec_group[index].spec_id" placeholder="请输入" />
                    </template>
                </Table>
            </div>
        </Card>

        <Card class="mt_20" title="参数设置" dis-hover>
            <div class="form_item">
                <div class="form_label">添加参数</div>
                <Select multiple class="width_500" v-model="formData.goodParam" @on-change="onChangeParameter" placeholder="请选择参数" not-found-text="当前商品分类下没有参数">
                    <Option v-for="info in paramList" :key="info.id" :value="info.id">{{ info.title }}</Option>
                </Select>
            </div>

            <div class="form_item">
                <div class="form_label align_self_start">参数信息</div>
                <div>
                    <div v-for="(info, idx) in formData.parameter" :key="info.id" class="relative flex flex_wrap align_center msg" :class="{ mt_20: idx > 0 }">
                        <div class="form_label">{{ info.title || info.name }}</div>
                        <Input v-if="info.type == paramsTypeDef.text" class="width_300" v-model.trim="info.value" :placeholder="info.placeholder" />
                        <!--
                        <CheckboxGroup v-else-if="info.type == paramsTypeDef.multiple" v-model="info.value" @on-change="evt => onChangeParameterValue(evt, info)">
                            <Checkbox v-for="(opt, optIdx) in info.options" :label="opt" :key="'opt_' + optIdx"></Checkbox>
                        </CheckboxGroup>
                        -->
                        <Select class="width_500" multiple v-else-if="info.type == paramsTypeDef.multiple" v-model="info.value">
                            <Option v-for="(opt, optIdx) in info.options" :key="'opt_' + optIdx" :value="opt">{{ opt }}</Option>
                        </Select>
                        <RadioGroup v-else-if="info.type == paramsTypeDef.single" type="button" button-style="solid" v-model="info.value">
                            <Radio v-for="opt in info.options" :label="opt" :key="'opt_' + encodeURIComponent(info.title + opt)"></Radio>
                        </RadioGroup>
                    </div>
                </div>
            </div>
        </Card>

        <Card class="mt_20" title="其它设置" dis-hover>
            <div class="form_item">
                <div class="form_label must_input align_self_start">售后服务</div>
                <CheckboxGroup v-model="formData.afterSales">
                    <Checkbox label="1" border>允许买家申请退货</Checkbox>
                    <Checkbox label="2" border>7天无理由</Checkbox>
                </CheckboxGroup>
            </div>
            <div class="form_item">
                <div class="form_label must_input align_self_start">上架设置</div>
                <RadioGroup v-model="formData.status" type="button" button-style="solid">
                    <Radio label="1">立即上架</Radio>
                    <Radio label="0">暂不上架</Radio>
                </RadioGroup>
            </div>
            <div class="form_item">
                <div class="form_label must_input align_self_start">限购</div>
                <div>
                    <RadioGroup v-model="formData.isXiangou" type="button" button-style="solid">
                        <Radio label="1">设置每人限购数量</Radio>
                        <Radio label="0">不限购</Radio>
                    </RadioGroup>
                    <Input class="width_300 mt_20 block" v-if="formData.isXiangou == 1" v-model.trim="formData.quota" placeholder="输入每人限购数量" />
                </div>
            </div>

            <div class="form_item">
                <div class="form_label align_self_start">商品介绍图片</div>
                <uploadImageMultiple :width="100" :height="100" v-model="formData.note_pic" :len="5"></uploadImageMultiple>
            </div>
            <div class="form_item">
                <div class="form_label align_self_start">商品介绍图文</div>
                <editor v-model.trim="formData.note"></editor>
            </div>
        </Card>

        <div class="mt_20 flex align_center justify_center form_item">
            <i-button type="primary" ghost @click="onCloseEdit">取消</i-button>
            <i-button class="ml_50" type="success" :loading="ajaxLoading" @click="onConfirmEdit">保存</i-button>
        </div>
        <mySpin :loading="ajaxLoading"></mySpin>

        <Modal class="msg" :closable="false" v-model="showBatchSetting" :title="title" width="500" :loading="ajaxLoading">
            <div class="form_item">
                <div class="form_label must_input">批量值</div>
                <Input class="width_300" v-model.trim="batchValue" :maxlength="9" show-word-limit :placeholder="batchFieldPlaceholder"></Input>
            </div>

            <div slot="footer" class="relative width_100_percent flex align_center justify_center">
                <Button type="primary" ghost @click="onCancelBatchSetting">取消</Button>
                <Button class="ml_100" type="primary" :loading="ajaxLoading" @click="onConfirmBatchSetting">确定</Button>
            </div>
        </Modal>
    </Card>
</template>
<script>
import UploadImage from '@/components/uploadImage';
import uploadImageMultiple from '@/components/uploadImageMultiple';
import compAddSpec from './compAddSpec.vue';
import editor from '@/components/editor';
import { getClassListData, paramsTypeDef } from './common';
import { reqGoodsParameter, reqGoodsSave, reqGoodsView, reqSearchSpec } from '@/lib/request/auth2';
import { getParamType, isNotEmptyString, type_array } from '@/lib/util';
// import {  } from '@/lib/request/auth';

export default {
    name: 'goodAdd',
    components: {
        UploadImage,
        uploadImageMultiple,
        editor,
        compAddSpec,
    },
    data() {
        this.paramsTypeDef = paramsTypeDef;
        return {
            title: null,
            maxSpecLength: 3,
            formData: {
                id: null,
                pic: [],
                thumbnail: null,
                title: null,
                marque: null,
                goodClass: null,
                afterSales: null,
                status: null,
                isXiangou: '0',
                quota: '0',
                note: null,
                note_pic: [],
                goodParam: [],
                parameter: [],
                spec: [],
            },
            arrPic: [''],
            isEdit: false,
            isCopy: false,
            classTypeList: [],
            paramList: [],
            specificationList: [],
            showBatchSetting: false,
            batchValue: null,
            batchField: null,
            batchFieldPlaceholder: null,
            spec_group: [],
            specValue: [],
            spec_column: [
                {
                    key: 'data',
                    title: '商品规格',
                },
                {
                    slot: 'spec_no',
                    title: '商品编码',
                    renderHeader: (h, { column, index }) => {
                        return [
                            h('span', '商品编码'),
                            h('Button', {
                                props: { type: 'warning', size: 'small', icon: 'ios-create', shape: 'circle' },
                                on: {
                                    click: () => {
                                        this.showValueSetting('spec_no', '请输入商品编码');
                                    },
                                },
                            }),
                        ];
                    },
                },
                {
                    slot: 'spec_model',
                    title: '商品型号',
                    renderHeader: (h, { column, index }) => {
                        return [
                            h('span', '商品型号'),
                            h('Button', {
                                props: { type: 'warning', size: 'small', icon: 'ios-create', shape: 'circle' },
                                on: {
                                    click: () => {
                                        this.showValueSetting('spec_model', '请输入商品型号');
                                    },
                                },
                            }),
                        ];
                    },
                },
                {
                    slot: 'price',
                    title: '价格(元)',
                    renderHeader: (h, { column, index }) => {
                        return [
                            h('span', '价格(元)'),
                            h('Button', {
                                props: { type: 'warning', size: 'small', icon: 'ios-create', shape: 'circle' },
                                on: {
                                    click: () => {
                                        this.showValueSetting('price', '请输入价格(元)');
                                    },
                                },
                            }),
                        ];
                    },
                },
                {
                    slot: 'stock',
                    title: '库存',
                    renderHeader: (h, { column, index }) => {
                        return [
                            h('span', '库存'),
                            h('Button', {
                                props: { type: 'warning', size: 'small', icon: 'ios-create', shape: 'circle' },
                                on: {
                                    click: () => {
                                        this.showValueSetting('stock', '请输入库存');
                                    },
                                },
                            }),
                        ];
                    },
                },
                {
                    slot: 'spec_id',
                    title: 'ERP规格ID',
                    renderHeader: (h, { column, index }) => {
                        return [
                            h('span', 'ERP规格ID'),
                            h('Button', {
                                props: { type: 'warning', size: 'small', icon: 'ios-create', shape: 'circle' },
                                on: {
                                    click: () => {
                                        this.showValueSetting('spec_id', '请输入ERP规格ID');
                                    },
                                },
                            }),
                        ];
                    },
                },
            ],
        };
    },
    mounted() {
        this.routeIdInject();
        this.isCopy = this.currentRoute.query.copy == 1;
        // this.isEdit = this.isCopy ? false : this.isEdit;
        this.title = this.isEdit ? '编辑商品' : '添加商品';
        this.getClassTypeList();
        this.getSpecList();
        this.getDetail();
        this.getParamList(null);
    },
    watch: {
        //
    },
    methods: {
        onChangeParameterValue(evt, info) {
            console.log('onChangeParameterValue', evt, info);
        },
        //获取分类列表数据
        getClassTypeList() {
            getClassListData().then(list => {
                this.classTypeList = list;
            });
        },
        //商品参数列表
        getParamList(cid, cb = null) {
            reqGoodsParameter({
                status: '1',
                cid,
                pageNums: 1,
                pageSize: 200,
            }).then(res => {
                let paramList = res?.data?.list || [];
                this.paramList = paramList;
                hm.isFunction(cb) && cb();
            });
        },
        //获取规格
        getSpecList() {
            this.showAjaxLoading();
            reqSearchSpec()
                .then(res => {
                    let list = res?.data?.list || [];
                    list.forEach(info => {
                        info.disabled = false;
                    });
                    this.specificationList = list;
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        //选择商品分类
        onChangeClassType(evt, isChange = 1) {
            // console.log('onChangeClassType evt', evt);
            let [pcid, cid] = evt || ['', ''];
            if (!!cid) {
                // this.getParamList(cid, () => {
                //     if (isChange == 1) {
                //         if (this.formData.goodParam.length > 0) {
                //             this.formData.goodParam.splice(0, this.formData.goodParam.length);
                //         }
                //     }
                // });
            } else {
                // this.paramList = [];
                // this.formData.goodParam.splice(0, this.formData.goodParam.length);
            }
        },
        //获取详情
        getDetail() {
            if (this.isEdit || this.isCopy) {
                this.showAjaxLoading();
                reqGoodsView({ id: this.formData.id })
                    .then(res => {
                        this.dataInjectFormData(res);
                        this.isCopy && (this.formData.id = null);
                        let { spec_group, pcid, cid } = res?.data?.row || {};
                        let { pic, spec, afterSales, note_pic, quota, parameter } = this.formData || {};
                        this.formData.pic = !!pic ? pic.split(',') : [];
                        this.formData.spec = JSON.parse(spec || '[]');
                        this.formData.spec.forEach(info => {
                            info.data = info.value.join(',');
                        });
                        this.formData.afterSales = afterSales.split(',');
                        this.formData.note_pic = !!note_pic ? note_pic.split(',') : [];
                        this.formData.isXiangou = quota == 0 ? '0' : '1';
                        this.formData.goodClass = [pcid, cid];
                        let _parameter = JSON.parse(parameter || '[]');
                        _parameter.forEach(info => {
                            info.type_info = JSON.parse(info.type_info || '{}');
                            if (info.type == paramsTypeDef.text) {
                                info.placeholder = info.type_info.placeholder;
                            } else {
                                info.value = info.type == paramsTypeDef.multiple ? info.value.split(',') : info.value;
                                info.options = info.type_info.options || [];
                            }
                        });
                        this.formData.parameter = _parameter;

                        if (_parameter.length > 0) {
                            this.formData.goodParam = _parameter.map(info => info.id);
                        }

                        spec_group.forEach(info => {
                            if (this.isCopy) {
                                info.id = null;
                            }
                        });
                        this.spec_group = spec_group || [];
                        this.specValue = (this.formData.spec || []).map(info => info.id);

                        this.onChangeClassType([pcid, cid], 0);

                        // console.log(JSON.stringify(this.formData.parameter, null, '\t'));
                        // console.log(JSON.stringify(this.formData, null, '\t'));
                        // console.log(JSON.stringify(this.formData.spec, null, '\t'));
                    })
                    .finally(() => {
                        this.hideAjaxLoading();
                        if (this.isCopy) {
                            window.setTimeout(() => {
                                this.isEdit = false;
                            }, 1500);
                        }
                    });
            }
        },
        //取消
        onCloseEdit() {
            this.$router.back();
            window.setTimeout(() => {
                this.hideAjaxLoading();
            }, 500);
        },
        //确认
        onConfirmEdit() {
            let params = window.structuredClone(this.formData);

            // if (1 == 1) {
            // console.log(JSON.stringify(params, null, '\t'));
            // console.log(JSON.stringify(this.spec_group, null, '\t'));
            //     return;
            // }

            let { pic, thumbnail, title, goodClass, afterSales, status, isXiangou, note_pic, note } = params || {};
            if (pic.length <= 0) {
                this.fadeWarning('至少选择一张商品图片');
                return;
            }
            if ((goodClass || []).length <= 0) {
                this.fadeWarning('请选择商品分类');
                return;
            }
            let vs = [
                { value: thumbnail, tip: '请选择商品缩略图' },
                { value: title, tip: '请输入商品名称' },
                { value: status, tip: '请选择上架设置' },
                { value: isXiangou, tip: '请选择限购' },
            ];

            hm.validateForm(vs)
                .then(() => {
                    // 校验规格
                    let specTip = null;
                    let spec = params.spec || [];
                    if (spec.length > 0) {
                        for (let i = 0; i < spec.length; i++) {
                            if ((spec[i].value || []).length <= 0) {
                                specTip = `规格-[${spec[i].name}]必须选择一项值`;
                                break;
                            }
                        }
                    }
                    if (hm.isNotEmptyString(specTip)) {
                        this.fadeWarning(specTip);
                        return;
                    }
                    spec.forEach(info => {
                        delete info.data;
                        delete info.disabled;
                    });
                    params.spec = JSON.stringify(spec);

                    //校验 spec_group
                    let spec_group = window.structuredClone(this.spec_group || []);
                    let specGroupTip = null;
                    if (spec_group.length > 0) {
                        for (let i = 0; i < spec_group.length; i++) {
                            let { data, spec_no, spec_model, price, stock } = spec_group[i] || {};
                            let vs = [
                                { value: spec_no, tip: `请输入【${data}】的商品编码` },
                                { value: spec_model, tip: `请输入【${data}】的商品型号` },
                                { value: price, tip: `请输入【${data}】的价格` },
                                { value: stock, tip: `请输入【${data}】的库存` },
                            ];
                            specGroupTip = hm.validateFormSync(vs);
                            if (hm.isNotEmptyString(specGroupTip)) {
                                break;
                            }
                        }
                    }
                    if (hm.isNotEmptyString(specGroupTip)) {
                        this.fadeWarning(specGroupTip);
                        return;
                    }
                    delete spec_group.myid;
                    params.spec_group = JSON.stringify(spec_group);

                    //校验商品参数
                    let parameterTip = null;
                    let goodParam = params.parameter || [];
                    if (goodParam.length > 0) {
                        for (let i = 0; i < goodParam.length; i++) {
                            let parameterInfo = goodParam[i];

                            if (parameterInfo.type == paramsTypeDef.text && !isNotEmptyString(parameterInfo.value)) {
                                parameterTip = `参数【${parameterInfo.title}】的值不能为空`;
                                break;
                            } else if ((parameterInfo.type == paramsTypeDef.single || parameterInfo.type == paramsTypeDef.multiple) && (parameterInfo.value || []).length <= 0) {
                                parameterTip = `参数【${parameterInfo.title}】至少选择一项]`;
                                break;
                            }
                        }
                    }
                    if (hm.isNotEmptyString(parameterTip)) {
                        this.fadeWarning(parameterTip);
                        return;
                    }
                    let parameter = [];
                    goodParam.forEach(info => {
                        parameter.push({
                            id: info.id,
                            name: info.title,
                            value: getParamType(info.value) == type_array ? info.value.join(',') : info.value,
                            type: info.type,
                            type_info: info.type_info,
                        });
                    });
                    //校验售后服务
                    if ((afterSales || []).length <= 0) {
                        this.fadeWarning('请选择售后服务');
                        return;
                    }
                    //校验商品介绍
                    if (!isNotEmptyString(note) && note_pic.length <= 0) {
                        this.fadeWarning('请选择商品介绍图片或输入商品介绍图文');
                        return;
                    }
                    this.showAjaxLoading();

                    params.parameter = JSON.stringify(parameter);
                    delete params.goodParam;

                    params.pic = params.pic.join(',');
                    [params.pcid, params.cid] = params.goodClass;
                    delete params.goodClass;
                    params.afterSales = params.afterSales.join(',');
                    params.quota = params.isXiangou == 0 ? '0' : params.quota;
                    delete params.isXiangou;
                    if ((params.note_pic || []).length > 0) {
                        params.note_pic = params.note_pic.join(',');
                    } else {
                        params.note_pic = '';
                    }

                    console.log('商品参数为：', params.parameter);
                    console.log(JSON.stringify(params, null, '\t'));
                    // if (1 == 1) {
                    //     return;
                    // }

                    reqGoodsSave(params)
                        .then(res => {
                            this.fadeAlert(res.errmsg);
                            this.onCloseEdit();
                        })
                        .catch(() => {
                            this.hideAjaxLoading();
                        });
                })
                .catch(msg => {
                    this.hideAjaxLoading();
                    this.fadeWarning(msg);
                });
        },
        //选择商品规格
        onChangeSpecification(evt) {
            if (this.isEdit) {
                return;
            }

            let goodSpec = this.specificationList.filter(info => evt.includes(info.id));
            goodSpec.forEach(info => {
                if (this.formData.spec.findIndex(item => item.id == info.id) < 0) {
                    info.value = [];
                } else {
                    let inDetail = this.formData.spec.find(item => item.id == info.id);
                    info.value = inDetail.value || [];
                }
            });
            this.formData.spec = goodSpec;
            // console.log(' this.onChangeSpecification', JSON.stringify(this.formData.spec, null, '\t'));
            let isMaxLen = this.formData.spec.length >= this.maxSpecLength;
            let specIds = this.formData.spec.map(info => info.id);
            this.specificationList.forEach(info => {
                if (!specIds.includes(info.id)) {
                    info.disabled = isMaxLen;
                }
            });
            this.onChangeSpecValue();
        },
        //选择规格值
        onChangeSpecValue(evt) {
            if (this.isEdit) {
                return;
            }
            let spec = this.formData.spec;
            let arr = [];
            spec.forEach(info => {
                arr.push(info.value || []);
            });

            let result = [[]];
            // console.log('=-=-=-=-=-=-=-=-=-=>', JSON.stringify(arr, null, '\t'));
            arr.map(item => {
                let res = [];
                result.map(y => {
                    item.map(z => {
                        res.push([...y, z]);
                    });
                });
                result = res;
            });
            let finalResult = [];
            result.forEach(res => {
                finalResult.push(res.join('|'));
            });
            // console.log('--------------------->finalResult=', finalResult);
            let oldSpecGroup = window.structuredClone(this.spec_group);
            this.spec_group.length = 0;
            finalResult.forEach(data => {
                let myid = decodeURIComponent(data);
                let oldItem = oldSpecGroup.find(info => info.myid == myid) || {};
                this.spec_group.push({
                    myid,
                    data,
                    spec_no: oldItem.spec_no || null,
                    spec_model: oldItem.spec_model || null,
                    price: oldItem.price || null,
                    stock: oldItem.stock || null,
                    spec_id: oldItem.spec_id || null,
                });
            });
            // console.log(JSON.stringify(finalResult, null, '\t'));
        },
        //选择商品参数
        onChangeParameter(evt) {
            let parameter = this.paramList.filter(info => evt.includes(info.id));
            parameter.forEach(info => {
                if (this.formData.parameter.findIndex(item => item.id == info.id) < 0) {
                    info.value = null;
                } else {
                    let detail = this.formData.parameter.find(item => item.id == info.id) || {};
                    info.value = detail.value;
                }
                console.log(info.type_info);
                let typeInfo = JSON.parse(info.type_info);
                if (info.type == paramsTypeDef.text) {
                    info.placeholder = typeInfo.placeholder;
                } else {
                    info.options = typeInfo.options || [];
                }
            });
            this.formData.parameter = parameter;
            console.log(JSON.stringify(this.formData.parameter, null, '\t'));
        },
        showValueSetting(field, placeholder) {
            this.batchValue = null;
            this.batchField = field;
            this.batchFieldPlaceholder = placeholder;
            this.showBatchSetting = true;
        },
        onCancelBatchSetting() {
            this.showBatchSetting = false;
        },
        onConfirmBatchSetting() {
            if (!!!this.batchValue) {
                this.fadeWarning('请输入值');
                return;
            }
            this.spec_group.forEach(info => {
                info[this.batchField] = this.batchValue;
            });
            this.onCancelBatchSetting();
        },
    },
};
</script>
<style lang="less" scoped>
.msg {
    .form_label {
        position: relative;
        width: 100px;
        text-align: left;
        &::after {
            content: '';
        }
    }
}
</style>
